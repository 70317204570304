import { get, post } from '@/service/index.js'

export const getDataTable = param => {
  return get('/declare/data/' + param).then(res => res)
}

export const uploadDataTemplate = data => {
  return post('/declare/data/upload', data).then(res => res)
}


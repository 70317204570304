<template>
  <div class="p-wrap">
    <div class="p-header">
      <p-header :title="info.name" spacer>
<!--        <el-upload :on-progress="handleUpload" action="#" accept=".xlsx" :multiple="false">-->
<!--          <el-button type="default">上传</el-button>-->
<!--        </el-upload>-->
        <el-button type="default" @click="handleExport">导出</el-button>
        <el-button type="default" @click="handleBack">返回</el-button>
      </p-header>
    </div>
    <div class="p-main">
      <div class="xlsx">
        <div id="dataTemplate"></div>
      </div>
    </div>
  </div>
</template>

<script>
import PHeader from '@/components/pdt/PHeader'
import LuckyExcel from 'luckyexcel'
import { getDataTable, uploadDataTemplate } from '@/service/declare/setting/ent-subject-dict.js'
import { exportExcel } from './export'
import { baseURL } from '@/service/index'

export default {
  name: 'DeclareDataDetail',
  components: { PHeader },
  data () {
    return {
      info: {
        id: decodeURIComponent(this.$route.query.id),
        name: decodeURIComponent(this.$route.query.name)
      }
    }
  },
  mounted () {
    this.initXlsx()
  },
  methods: {
    async initXlsx () {
      var options = {
        container: 'dataTemplate',
        loadUrl: `${baseURL}/declare/data/${this.info.id}`,
        showinfobar: false,
        showtoolbar: false,
        enableAddRow: false,
        enableAddCol: false,
        userInfo: false,
        showsheetbarConfig: {
          add: false, //新增sheet
          menu: false, //sheet管理菜单
        },
        forceCalculation: true, // 强制计算公式
        showinfobar: false,
        showtoolbar: false,
        allowCopy: false,
      }
      luckysheet.create(options)
    },
    // 导入
    handleUpload (event, files) {
      if (files == null || files.length == 0) {
        this.toast('上传文件失败', 'warning')
        return
      }
      let name = files.name
      let suffixArr = name.split('.'), suffix = suffixArr[suffixArr.length - 1]
      if (suffix != 'xlsx') {
        this.toast('上传文件非xlsx文件，请重新选择', 'warning')
        return
      }
      LuckyExcel.transformExcelToLucky(files.raw, exportJSON => {
        if (exportJSON.sheets == null || exportJSON.sheets.length == 0) {
          this.toast('未解析到表单数据，请确认上传的表单是否异常', 'warning')
          return
        }
        uploadDataTemplate({
          id: this.info.id,
          bizType: 'DATA',
          dataType: 'SYS',
          sheets: exportJSON.sheets
        }).then(({ success }) => {
          if (success) this.toast('模版导入成功', 'success')
        })
      })

    },
    // 导出
    handleExport () {
      exportExcel(luckysheet.getAllSheets(), '下载')
    },
    // 返回
    handleBack () {
      this.$router.push('/declare/setting/data')
    }
  }
}
</script>

<style scoped lang="scss">
  .p-wrap {
    height: 100%;
    display: flex;
    flex-direction: column;

    .p-header {
      width: auto;
      padding: 0 24px;
    }

    .p-main {
      display: flex;
      flex: auto;
      padding: 24px;
      margin-top: 24px;
      background-color: #fff;

      .xlsx {
        flex: auto;
        display: flex;
        padding: 18px;
        border: 1px solid #E5E5E5;

        #luckysheetloadingdata {
          display: none;
        }

        #dataTemplate {
          flex: auto;
        }
      }
    }
  }
</style>